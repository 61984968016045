import React, { FC, useEffect, useRef } from "react";
import useWindowSize from "../../../helpers/UseWindowSize";
import { LivingLeft, LivingRight, LivingWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Living: FC = () => {
  const livingWrapperRef = useRef<HTMLDivElement>(null);
  const livingRightRef = useRef<HTMLDivElement>(null);
  const livingLeftRef = useRef<HTMLDivElement>(null);
  const livingHeaderRef = useRef<HTMLHeadingElement>(null);
  const livingTextImgRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: livingWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          livingRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          livingLeftRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          livingHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          livingTextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      penthouse1: file(relativePath: { eq: "penthouse-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      penthouse2: file(relativePath: { eq: "penthouse-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const size = useWindowSize();
  return (
    <LivingWrapper ref={livingWrapperRef}>
      <LivingLeft ref={livingLeftRef}>
        <Img
          fluid={
            size.width < 576
              ? data.penthouse1.childImageSharp.fluid
              : data.penthouse2.childImageSharp.fluid
          }
          alt={"Parkhouse living"}
          loading={"eager"}
        />
      </LivingLeft>

      <LivingRight ref={livingRightRef}>
        <Img
          fluid={
            size.width < 576
              ? data.penthouse2.childImageSharp.fluid
              : data.penthouse1.childImageSharp.fluid
          }
          alt={"Parkhouse living"}
          loading={"eager"}
        />
        <div>
          <h2 className={"body-title"} ref={livingHeaderRef}>
            SEAMLESS INDOOR / OUTDOOR LIVING
          </h2>
          <p className={"body-copy"} ref={livingTextImgRef}>
            The two-story penthouse homes float above the treetops with large
            rooftop terraces overlooking the neighborhood. With their own powder
            room and dumbwaiter, the roof terraces’ every convenience was
            considered, including the option to include your own hot tub and
            expansive outdoor kitchen.
          </p>
        </div>
      </LivingRight>
    </LivingWrapper>
  );
};

export default Living;
