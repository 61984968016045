import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const LivingWrapper = styled.div`
  display: flex;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `}
  ${mediaMax.phoneXL`
    flex-wrap: wrap;
  `};
`;
export const LivingLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 49%;
  ${mediaMax.tabletLandscape`
    justify-content: flex-start;
    width: 31.25%;
  `}
  ${mediaMax.phoneXL`
    width: 100%;
  `};
`;
export const LivingRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 38%;
  .gatsby-image-wrapper {
    margin: 0 0 48px 0;
  }
  ${mediaMax.tabletLandscape`
    width: 61.6%;
    & div{
      margin-left: -17.5vw;
      width: 65vw;
    }
  `};
  ${mediaMax.phoneXL`
    width: 100%;
    .gatsby-image-wrapper {
      width: 57%;
      margin: 48px auto;
    }
    & div{
      margin-left: 0;
      width: 100%;
    }
  `};
`;
