import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import useWindowSize from "../helpers/UseWindowSize";
// import IntroImage from "../components/intro-image";
import IntroText from "../components/intro-text";
import FullscreenImage from "../components/fullscreen-image";
import Living from "../components/penthouses/living";
import PageTransitionSection from "../components/page-transition-section";
import FirstLoadContext from "../context";
import HomeSlider from "../components/home/home-slider";
import { Helmet } from "react-helmet";
import FooterSeo from "../components/footer-seo";

const introText = {
  title: "PENTHOUSES",
  text: "Privacy-seekers have a unique opportunity to own one of the few Penthouses. These exclusive top-floor residences with their own rooftop decks offer an indoor-outdoor oasis overlooking all of Uptown Newport and the Parkhouse Residences’ adjacent greenspace.",
};

const IntroSlider = styled(HomeSlider)`
  height: calc(100vh - 80px);
  object-position: center bottom;
  .slick-slide:nth-child(3) img {
    object-position: center bottom !important;
  }
`;

const Penthouses = () => {
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);
  const size = useWindowSize();
  return (
    <>
      <Helmet>
        <title>Penthouses in Newport Beach, Newport Beach Penthouses | Parkhouse Residences Newport Beach</title>
        <meta name="description" content="Exclusive top-floor penthouses with private rooftop decks offer an indoor / outdoor oasis overlooking all of Uptown Newport and the Parkhouse Residences adjacent greenspace." />
      </Helmet>
      {/* <IntroImage imageSrc={"penthouses-hero.jpg"} /> */}
      <IntroSlider
        img1={"penthouses-hero.jpg"}
        // label1={"CONTEMPORARY KITCHEN"}
        img2={"penthouses-hero-2.jpg"}
      // label2={"CLASSIC KITCHEN"}
      />
      <IntroText title={introText.title} text={introText.text} />
      <Living />
      <FullscreenImage
        label={"Classic kitchen"}
        imageSrc={"penthouse-3.png"}
        withPadding={size.width < 1025 ? "100%" : "62.5%"}
        labelWidth={size.width < 1025 ? "80%" : "62.5%"}
      />
      <PageTransitionSection text={"CONTINUE TO"} linkTo={"amenities"} />
      <FooterSeo />
    </>
  );
};

export default Penthouses;
